import { getFirestore } from 'firebase/firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';


const app = firebase.initializeApp ({
  apiKey: "AIzaSyA0KJ8kcz9cSN4oDm3yicM4Z6JRGwrMaVc",
  authDomain: "royal-pearl-e3254.firebaseapp.com",
  projectId: "royal-pearl-e3254",
  storageBucket: "royal-pearl-e3254.appspot.com",
  messagingSenderId: "33553349390",
  appId: "1:33553349390:web:7ff79c1d1f1e3092b3755f",
  measurementId: "G-F7BSTZ6T4K"
});
export const storage = firebase.storage();
export const auth = app.auth()
export const db = getFirestore(app)
export default app

